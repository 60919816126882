export function DashedLine({ className }: { className?: string }) {
  return (
    <svg
      width="852"
      height="150"
      viewBox="0 0 852 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M884.748 6.3422C811.227 -2.16202 660.706 -5.78388 646.797 47.7625C631.626 106.164 708.508 110.099 707.026 72.7194C705.544 35.3401 635.866 -24.1469 429.294 59.6567C222.721 143.46 106.557 160.492 47.4376 140.403C14.8 129.312 4.90172 111.793 1.078 106.497"
        stroke="#9DA3D5"
        stroke-width="2"
        stroke-dasharray="3 3"
      />
    </svg>
  );
}

export function RightIllustration({ className }: { className?: string }) {
  return (
    <svg
      width="156"
      height="165"
      viewBox="0 0 156 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clip-path="url(#clip0_307_6561)">
        <path
          d="M7.35908 148.372C7.35908 150.21 5.88358 151.688 4.04819 151.688C2.21281 151.688 0.737305 150.21 0.737305 148.372C0.737305 146.534 2.21281 145.056 4.04819 145.056C5.88358 145.056 7.35908 146.534 7.35908 148.372Z"
          fill="#9DA3D5"
        />
        <path
          d="M28.5573 148.372C28.5573 150.21 27.0818 151.688 25.2464 151.688C23.4111 151.688 21.9355 150.21 21.9355 148.372C21.9355 146.534 23.4111 145.056 25.2464 145.056C27.0818 145.056 28.5573 146.534 28.5573 148.372Z"
          fill="#9DA3D5"
        />
        <path
          d="M49.7556 148.372C49.7556 150.21 48.2801 151.688 46.4447 151.688C44.6093 151.688 43.1338 150.21 43.1338 148.372C43.1338 146.534 44.6093 145.056 46.4447 145.056C48.2801 145.056 49.7556 146.534 49.7556 148.372Z"
          fill="#9DA3D5"
        />
        <path
          d="M70.9548 148.372C70.9548 150.21 69.4793 151.688 67.6439 151.688C65.8085 151.688 64.333 150.21 64.333 148.372C64.333 146.534 65.8085 145.056 67.6439 145.056C69.4793 145.056 70.9548 146.534 70.9548 148.372Z"
          fill="#9DA3D5"
        />
        <path
          d="M92.153 148.372C92.153 150.21 90.6775 151.688 88.8421 151.688C87.0068 151.688 85.5312 150.21 85.5312 148.372C85.5312 146.534 87.0068 145.056 88.8421 145.056C90.6775 145.056 92.153 146.534 92.153 148.372Z"
          fill="#9DA3D5"
        />
        <path
          d="M113.351 148.372C113.351 150.21 111.876 151.688 110.04 151.688C108.205 151.688 106.729 150.21 106.729 148.372C106.729 146.534 108.205 145.056 110.04 145.056C111.876 145.056 113.351 146.534 113.351 148.372Z"
          fill="#9DA3D5"
        />
        <path
          d="M134.55 148.372C134.55 150.21 133.074 151.688 131.239 151.688C129.403 151.688 127.928 150.21 127.928 148.372C127.928 146.534 129.403 145.056 131.239 145.056C133.074 145.056 134.55 146.534 134.55 148.372Z"
          fill="#9DA3D5"
        />
        <path
          d="M7.35908 128.33C7.35908 130.168 5.88358 131.646 4.04819 131.646C2.21281 131.646 0.737305 130.168 0.737305 128.33C0.737305 126.492 2.21281 125.014 4.04819 125.014C5.88358 125.014 7.35908 126.492 7.35908 128.33Z"
          fill="#9DA3D5"
        />
        <path
          d="M28.5573 128.33C28.5573 130.168 27.0818 131.646 25.2464 131.646C23.4111 131.646 21.9355 130.168 21.9355 128.33C21.9355 126.492 23.4111 125.014 25.2464 125.014C27.0818 125.014 28.5573 126.492 28.5573 128.33Z"
          fill="#9DA3D5"
        />
        <path
          d="M49.7556 128.33C49.7556 130.168 48.2801 131.646 46.4447 131.646C44.6093 131.646 43.1338 130.168 43.1338 128.33C43.1338 126.492 44.6093 125.014 46.4447 125.014C48.2801 125.014 49.7556 126.492 49.7556 128.33Z"
          fill="#9DA3D5"
        />
        <path
          d="M70.9548 128.33C70.9548 130.168 69.4793 131.646 67.6439 131.646C65.8085 131.646 64.333 130.168 64.333 128.33C64.333 126.492 65.8085 125.014 67.6439 125.014C69.4793 125.014 70.9548 126.492 70.9548 128.33Z"
          fill="#9DA3D5"
        />
        <path
          d="M92.153 128.33C92.153 130.168 90.6775 131.646 88.8421 131.646C87.0068 131.646 85.5312 130.168 85.5312 128.33C85.5312 126.492 87.0068 125.014 88.8421 125.014C90.6775 125.014 92.153 126.492 92.153 128.33Z"
          fill="#9DA3D5"
        />
        <path
          d="M113.351 128.33C113.351 130.168 111.876 131.646 110.04 131.646C108.205 131.646 106.729 130.168 106.729 128.33C106.729 126.492 108.205 125.014 110.04 125.014C111.876 125.014 113.351 126.492 113.351 128.33Z"
          fill="#9DA3D5"
        />
        <path
          d="M134.55 128.33C134.55 130.168 133.074 131.646 131.239 131.646C129.403 131.646 127.928 130.168 127.928 128.33C127.928 126.492 129.403 125.014 131.239 125.014C133.074 125.014 134.55 126.492 134.55 128.33Z"
          fill="#9DA3D5"
        />
        <path
          d="M7.35908 108.289C7.35908 110.127 5.88358 111.605 4.04819 111.605C2.21281 111.605 0.737305 110.127 0.737305 108.289C0.737305 106.451 2.21281 104.973 4.04819 104.973C5.88358 104.973 7.35908 106.451 7.35908 108.289Z"
          fill="#9DA3D5"
        />
        <path
          d="M28.5573 108.289C28.5573 110.127 27.0818 111.605 25.2464 111.605C23.4111 111.605 21.9355 110.127 21.9355 108.289C21.9355 106.451 23.4111 104.973 25.2464 104.973C27.0818 104.973 28.5573 106.451 28.5573 108.289Z"
          fill="#9DA3D5"
        />
        <path
          d="M49.7556 108.289C49.7556 110.127 48.2801 111.605 46.4447 111.605C44.6093 111.605 43.1338 110.127 43.1338 108.289C43.1338 106.451 44.6093 104.973 46.4447 104.973C48.2801 104.973 49.7556 106.451 49.7556 108.289Z"
          fill="#9DA3D5"
        />
        <path
          d="M70.9548 108.289C70.9548 110.127 69.4793 111.605 67.6439 111.605C65.8085 111.605 64.333 110.127 64.333 108.289C64.333 106.451 65.8085 104.973 67.6439 104.973C69.4793 104.973 70.9548 106.451 70.9548 108.289Z"
          fill="#9DA3D5"
        />
        <path
          d="M92.153 108.289C92.153 110.127 90.6775 111.605 88.8421 111.605C87.0068 111.605 85.5312 110.127 85.5312 108.289C85.5312 106.451 87.0068 104.973 88.8421 104.973C90.6775 104.973 92.153 106.451 92.153 108.289Z"
          fill="#9DA3D5"
        />
        <path
          d="M113.351 108.289C113.351 110.127 111.876 111.605 110.04 111.605C108.205 111.605 106.729 110.127 106.729 108.289C106.729 106.451 108.205 104.973 110.04 104.973C111.876 104.973 113.351 106.451 113.351 108.289Z"
          fill="#9DA3D5"
        />
        <path
          d="M134.55 108.289C134.55 110.127 133.074 111.605 131.239 111.605C129.403 111.605 127.928 110.127 127.928 108.289C127.928 106.451 129.403 104.973 131.239 104.973C133.074 104.973 134.55 106.451 134.55 108.289Z"
          fill="#9DA3D5"
        />
        <path
          d="M7.35908 88.2474C7.35908 90.0855 5.88358 91.5633 4.04819 91.5633C2.21281 91.5633 0.737305 90.0855 0.737305 88.2474C0.737305 86.4092 2.21281 84.9315 4.04819 84.9315C5.88358 84.9315 7.35908 86.4092 7.35908 88.2474Z"
          fill="#9DA3D5"
        />
        <path
          d="M28.5573 88.2474C28.5573 90.0855 27.0818 91.5633 25.2464 91.5633C23.4111 91.5633 21.9355 90.0855 21.9355 88.2474C21.9355 86.4092 23.4111 84.9315 25.2464 84.9315C27.0818 84.9315 28.5573 86.4092 28.5573 88.2474Z"
          fill="#9DA3D5"
        />
        <path
          d="M49.7556 88.2474C49.7556 90.0855 48.2801 91.5633 46.4447 91.5633C44.6093 91.5633 43.1338 90.0855 43.1338 88.2474C43.1338 86.4092 44.6093 84.9315 46.4447 84.9315C48.2801 84.9315 49.7556 86.4092 49.7556 88.2474Z"
          fill="#9DA3D5"
        />
        <path
          d="M70.9548 88.2474C70.9548 90.0855 69.4793 91.5633 67.6439 91.5633C65.8085 91.5633 64.333 90.0855 64.333 88.2474C64.333 86.4092 65.8085 84.9315 67.6439 84.9315C69.4793 84.9315 70.9548 86.4092 70.9548 88.2474Z"
          fill="#9DA3D5"
        />
        <path
          d="M92.153 88.2474C92.153 90.0855 90.6775 91.5633 88.8421 91.5633C87.0068 91.5633 85.5312 90.0855 85.5312 88.2474C85.5312 86.4092 87.0068 84.9315 88.8421 84.9315C90.6775 84.9315 92.153 86.4092 92.153 88.2474Z"
          fill="#9DA3D5"
        />
        <path
          d="M113.351 88.2474C113.351 90.0855 111.876 91.5633 110.04 91.5633C108.205 91.5633 106.729 90.0855 106.729 88.2474C106.729 86.4092 108.205 84.9315 110.04 84.9315C111.876 84.9315 113.351 86.4092 113.351 88.2474Z"
          fill="#9DA3D5"
        />
        <path
          d="M134.55 88.2474C134.55 90.0855 133.074 91.5633 131.239 91.5633C129.403 91.5633 127.928 90.0855 127.928 88.2474C127.928 86.4092 129.403 84.9315 131.239 84.9315C133.074 84.9315 134.55 86.4092 134.55 88.2474Z"
          fill="#9DA3D5"
        />
        <path
          d="M7.35908 68.2065C7.35908 70.0447 5.88358 71.5224 4.04819 71.5224C2.21281 71.5224 0.737305 70.0447 0.737305 68.2065C0.737305 66.3684 2.21281 64.8907 4.04819 64.8907C5.88358 64.8907 7.35908 66.3684 7.35908 68.2065Z"
          fill="#9DA3D5"
        />
        <path
          d="M28.5573 68.2065C28.5573 70.0447 27.0818 71.5224 25.2464 71.5224C23.4111 71.5224 21.9355 70.0447 21.9355 68.2065C21.9355 66.3684 23.4111 64.8907 25.2464 64.8907C27.0818 64.8907 28.5573 66.3684 28.5573 68.2065Z"
          fill="#9DA3D5"
        />
        <path
          d="M49.7556 68.2065C49.7556 70.0447 48.2801 71.5224 46.4447 71.5224C44.6093 71.5224 43.1338 70.0447 43.1338 68.2065C43.1338 66.3684 44.6093 64.8907 46.4447 64.8907C48.2801 64.8907 49.7556 66.3684 49.7556 68.2065Z"
          fill="#9DA3D5"
        />
        <path
          d="M70.9548 68.2065C70.9548 70.0447 69.4793 71.5224 67.6439 71.5224C65.8085 71.5224 64.333 70.0447 64.333 68.2065C64.333 66.3684 65.8085 64.8907 67.6439 64.8907C69.4793 64.8907 70.9548 66.3684 70.9548 68.2065Z"
          fill="#9DA3D5"
        />
        <path
          d="M92.153 68.2065C92.153 70.0447 90.6775 71.5224 88.8421 71.5224C87.0068 71.5224 85.5312 70.0447 85.5312 68.2065C85.5312 66.3684 87.0068 64.8907 88.8421 64.8907C90.6775 64.8907 92.153 66.3684 92.153 68.2065Z"
          fill="#9DA3D5"
        />
        <path
          d="M113.351 68.2065C113.351 70.0447 111.876 71.5224 110.04 71.5224C108.205 71.5224 106.729 70.0447 106.729 68.2065C106.729 66.3684 108.205 64.8907 110.04 64.8907C111.876 64.8907 113.351 66.3684 113.351 68.2065Z"
          fill="#9DA3D5"
        />
        <path
          d="M134.55 68.2065C134.55 70.0447 133.074 71.5224 131.239 71.5224C129.403 71.5224 127.928 70.0447 127.928 68.2065C127.928 66.3684 129.403 64.8907 131.239 64.8907C133.074 64.8907 134.55 66.3684 134.55 68.2065Z"
          fill="#9DA3D5"
        />
        <path
          d="M7.35908 48.1645C7.35908 50.0026 5.88358 51.4803 4.04819 51.4803C2.21281 51.4803 0.737305 50.0026 0.737305 48.1645C0.737305 46.3263 2.21281 44.8486 4.04819 44.8486C5.88358 44.8486 7.35908 46.3263 7.35908 48.1645Z"
          fill="#9DA3D5"
        />
        <path
          d="M28.5573 48.1645C28.5573 50.0026 27.0818 51.4803 25.2464 51.4803C23.4111 51.4803 21.9355 50.0026 21.9355 48.1645C21.9355 46.3263 23.4111 44.8486 25.2464 44.8486C27.0818 44.8486 28.5573 46.3263 28.5573 48.1645Z"
          fill="#9DA3D5"
        />
        <path
          d="M49.7556 48.1645C49.7556 50.0026 48.2801 51.4803 46.4447 51.4803C44.6093 51.4803 43.1338 50.0026 43.1338 48.1645C43.1338 46.3263 44.6093 44.8486 46.4447 44.8486C48.2801 44.8486 49.7556 46.3263 49.7556 48.1645Z"
          fill="#9DA3D5"
        />
        <path
          d="M70.9548 48.1645C70.9548 50.0026 69.4793 51.4803 67.6439 51.4803C65.8085 51.4803 64.333 50.0026 64.333 48.1645C64.333 46.3263 65.8085 44.8486 67.6439 44.8486C69.4793 44.8486 70.9548 46.3263 70.9548 48.1645Z"
          fill="#9DA3D5"
        />
        <path
          d="M92.153 48.1645C92.153 50.0026 90.6775 51.4803 88.8421 51.4803C87.0068 51.4803 85.5312 50.0026 85.5312 48.1645C85.5312 46.3263 87.0068 44.8486 88.8421 44.8486C90.6775 44.8486 92.153 46.3263 92.153 48.1645Z"
          fill="#9DA3D5"
        />
        <path
          d="M113.351 48.1645C113.351 50.0026 111.876 51.4803 110.04 51.4803C108.205 51.4803 106.729 50.0026 106.729 48.1645C106.729 46.3263 108.205 44.8486 110.04 44.8486C111.876 44.8486 113.351 46.3263 113.351 48.1645Z"
          fill="#9DA3D5"
        />
        <path
          d="M134.55 48.1645C134.55 50.0026 133.074 51.4803 131.239 51.4803C129.403 51.4803 127.928 50.0026 127.928 48.1645C127.928 46.3263 129.403 44.8486 131.239 44.8486C133.074 44.8486 134.55 46.3263 134.55 48.1645Z"
          fill="#9DA3D5"
        />
        <path
          d="M7.35908 28.1234C7.35908 29.9615 5.88358 31.4393 4.04819 31.4393C2.21281 31.4393 0.737305 29.9615 0.737305 28.1234C0.737305 26.2853 2.21281 24.8075 4.04819 24.8075C5.88358 24.8075 7.35908 26.2853 7.35908 28.1234Z"
          fill="#9DA3D5"
        />
        <path
          d="M28.5573 28.1234C28.5573 29.9615 27.0818 31.4393 25.2464 31.4393C23.4111 31.4393 21.9355 29.9615 21.9355 28.1234C21.9355 26.2853 23.4111 24.8075 25.2464 24.8075C27.0818 24.8075 28.5573 26.2853 28.5573 28.1234Z"
          fill="#9DA3D5"
        />
        <path
          d="M49.7556 28.1234C49.7556 29.9615 48.2801 31.4393 46.4447 31.4393C44.6093 31.4393 43.1338 29.9615 43.1338 28.1234C43.1338 26.2853 44.6093 24.8075 46.4447 24.8075C48.2801 24.8075 49.7556 26.2853 49.7556 28.1234Z"
          fill="#9DA3D5"
        />
        <path
          d="M70.9548 28.1234C70.9548 29.9615 69.4793 31.4393 67.6439 31.4393C65.8085 31.4393 64.333 29.9615 64.333 28.1234C64.333 26.2853 65.8085 24.8075 67.6439 24.8075C69.4793 24.8075 70.9548 26.2853 70.9548 28.1234Z"
          fill="#9DA3D5"
        />
        <path
          d="M92.153 28.1234C92.153 29.9615 90.6775 31.4393 88.8421 31.4393C87.0068 31.4393 85.5312 29.9615 85.5312 28.1234C85.5312 26.2853 87.0068 24.8075 88.8421 24.8075C90.6775 24.8075 92.153 26.2853 92.153 28.1234Z"
          fill="#9DA3D5"
        />
        <path
          d="M113.351 28.1234C113.351 29.9615 111.876 31.4393 110.04 31.4393C108.205 31.4393 106.729 29.9615 106.729 28.1234C106.729 26.2853 108.205 24.8075 110.04 24.8075C111.876 24.8075 113.351 26.2853 113.351 28.1234Z"
          fill="#9DA3D5"
        />
        <path
          d="M134.55 28.1234C134.55 29.9615 133.074 31.4393 131.239 31.4393C129.403 31.4393 127.928 29.9615 127.928 28.1234C127.928 26.2853 129.403 24.8075 131.239 24.8075C133.074 24.8075 134.55 26.2853 134.55 28.1234Z"
          fill="#9DA3D5"
        />
      </g>
      <defs>
        <clipPath id="clip0_307_6561">
          <rect
            width="155.011"
            height="163.913"
            fill="white"
            transform="translate(0.737305 0.820312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
