import { DashedLine, RightIllustration } from "~/svgs/home/VideoIllustrations";
import TitleAndHeader from "./TitleAndHeader";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";
import Blog from "~/types/Blog";
import { cn, localeAttr } from "~/utils/helper";
import { Link } from "@remix-run/react";
import useHost from "~/utils/hooks/useHost";
import { useTranslation } from "react-i18next";
import StarSvg from "~/svgs/common/Star";
import { FocusCircle } from "~/svgs/common/FocusCircle";
import parse from "html-react-parser";

export default function BlogSection({
  className,
  reverse = false,
  blog,
  locale,
}: {
  className?: string;
  reverse?: boolean;
  blog?: Blog | null;
  locale: string;
}) {
  const HOST = useHost();
  const { t } = useTranslation();

  return (
    <section
      className={cn(
        "cont relative flex min-w-full flex-col items-center justify-center gap-10 overflow-hidden overflow-x-hidden py-9 max-lg:gap-16 max-lg:py-6 lg:flex-row ",
        {
          "lg:flex-row-reverse": reverse,
        },
        className,
      )}
    >
      <div className="relative z-10 flex-1 lg:max-w-[50%]">
        <TitleAndHeader
          title={
            blog
              ? localeAttr(blog.attributes, "title", locale)
              : t("videoTitle")
          }
          header={t("videoHeader")}
          className="relative z-10"
          alignHeader
        />

        <p className="line-clamp-[3] h-[85px] text-[#646A90] [&_*]:[all:revert]">
          {blog
            ? parse(localeAttr(blog?.attributes, "content", locale) ?? "")
            : t("videoFooter")}
        </p>

        <Link
          to={blog ? `/blog/${blog.id}` : "/blogs/1"}
          className="text-lg text-Secondary-Crayola-Space-Cs-500 underline"
        >
          {t("readMore")}
        </Link>
      </div>
      <div className="relative z-10  flex-1 gap-10 p-2 before:absolute before:left-0 before:top-0 before:size-full before:rounded-3xl before:bg-Secondary-Dark-Blue-db-50  before:content-[''] md:p-6">
        <img
          src={
            blog
              ? `${HOST}${blog.attributes.image?.data.attributes.url}`
              : "/images/blog.png"
          }
          className="relative z-10 aspect-[17/10] w-full  rounded-2xl object-cover"
        />
        <RightIllustration
          className={twMerge(
            "absolute right-0 top-0 z-[-1] -translate-y-1/3 translate-x-1/3",
            clsx({
              "bottom-0 top-auto translate-y-1/3 rotate-180 ltr:left-0 ltr:right-auto ltr:-translate-x-1/3 rtl:left-auto rtl:right-0 rtl:translate-x-1/3":
                reverse,
            }),
          )}
        />
      </div>
      <StarSvg className="absolute top-[20%] z-0 scale-110 max-lg:hidden ltr:right-[20%] rtl:left-[10%]" />
      <FocusCircle className="absolute bottom-[20%] z-0 scale-110 ltr:right-[45%] rtl:left-[45%]" />
    </section>
  );
}
